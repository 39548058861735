<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">优惠券模板</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="type" placeholder="优惠券类型"   class="iw">
                        <el-option label="全部类型" value="" ></el-option>
                        <el-option label="抵扣券" value="1" ></el-option>
                        <el-option label="折扣券" value="2" ></el-option>
                </el-select>
                
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TYPE" label="优惠券类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">抵扣券</span>
                            <span v-else-if="scope.row.TYPE=='2'">折扣券</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="VALUE" label="对应优惠"></el-table-column>
                    <el-table-column prop="CONDITION" label="满多少可用"></el-table-column>
                    <el-table-column prop="REMARK" label="描述"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="bindCom(scope.row)" type="text" size="small" style="color:#409EFFC">指定商品</el-button>
                        <el-button @click="addCoupon(scope.row)" type="text" size="small" style="color:#67C23A">生成优惠券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="类型" prop="TYPE">
                    <el-radio v-model="postInfo.TYPE" label="1">抵扣券</el-radio>
                    <el-radio v-model="postInfo.TYPE" label="2">折扣券</el-radio>
                </el-form-item>
                <el-form-item label="对应优惠" prop="VALUE">
                    <el-input v-model="postInfo.VALUE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="满多少可用" prop="CONDITION">
                    <el-input v-model="postInfo.CONDITION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="指定商品" :visible.sync="comModal" width="800px">
            <el-form   label-width="100px" class="demo-uForm">
                <el-form-item label="商品">
                    <el-select v-model="comIds" placeholder="请选择需要指定的商品" multiple filterable clearable style="width:100%;">
                            <el-option v-for="item in comList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>   
                <el-form-item>
                    <el-button type="primary" @click="submitComForm()">提交</el-button>
                    <el-button @click="comModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="生成优惠券" :visible.sync="couModal" width="500px">
            <el-form :model="couInfo" status-icon :rules="courules"  ref="cForm" label-width="100px" class="demo-uForm">
                <el-form-item label="编号前缀" prop="COUPONPREFIX">
                     <el-input v-model="couInfo.COUPONPREFIX" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="起始号码" prop="STARTNUM">
                    <el-input v-model="couInfo.STARTNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="生成数量" prop="GENERATECOUNT">
                    <el-input v-model="couInfo.GENERATECOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitCouForm()">提交</el-button>
                <el-button @click="couModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CouponTManage",
  data() {

    var VALUE =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入对应优惠'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('对应优惠只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('对应优惠必须大于0'));
        }else {
          callback();
        }
    };

    var CONDITION =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入满多少可用'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('满多少可用只能是数字'));
        }else {
          callback();
        }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入描述"));
      } else {
        callback();
      }
    };
    
    var COUPONPREFIX= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编号前缀"));
      } else {
        callback();
      }
    };
    var GENERATECOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入生成数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('生成数量只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('生成数量必须大于0'));
        }else {
          callback();
        }
    };

    var STARTNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入起始号码'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('起始号码只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('起始号码必须大于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        type:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            VALUE: [{ validator: VALUE, trigger: "blur" }],
            CONDITION: [{ validator: CONDITION, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
        },

        nowTId:"",
        comList:[],
        comIds:[],
        comModal:false,

        couModal:false,
        couInfo:{},
        courules: {
            COUPONPREFIX: [{ validator: COUPONPREFIX, trigger: "blur" }],
            GENERATECOUNT: [{ validator: GENERATECOUNT, trigger: "blur" }],
            STARTNUM: [{ validator: STARTNUM, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getComList()
  },
  methods: {
    getComList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Commodity/GetCommodityListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.comList=response.data.DATA
            } 
        })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            TYPE:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CouponTemplate/GetCouponTemplateList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TYPE:"1",
            VALUE:"",
            CONDITION:"",
            REMARK:"",
        }
        this.doTypeStr="添加优惠券模板"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CouponTemplate/CreateCouponTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            TYPE:item.TYPE,
            VALUE:item.VALUE,
            CONDITION:item.CONDITION,
            REMARK:item.REMARK,
        }
        this.doTypeStr="修改优惠券模板"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CouponTemplate/EditCouponTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除此模板？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CouponTemplate/DeleteCouponTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    bindCom(item){
        this.nowTId=item.SNOWID
        this.comIds=[]
        this.getCouponComList(item.SNOWID)
    },
    getCouponComList(id){
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({COUPONTEMPLATESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({COUPONTEMPLATESNOWID:id})).toUpperCase())
        this.$http.post("CouponTemplate/GetTemplateCommodity", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    for(var i=0;i<d.length;i++){
                        this.comIds.push(d[i].SNOWID)
                    }
                } 
                this.comModal=true
            })
            .catch((error) => {
                this.hideLoading()
                this.comModal=true
            });
    },
    submitComForm(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                COUPONTEMPLATEID:this.nowTId,
                COMMODITYIDLIST:this.comIds
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CouponTemplate/CouponAddCommodity", md5_data)
                .then((response) => {
                    this.hideLoading()

                    if (response.data.SUCCESS) {
                        this.$message.success("已保存")
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                    this.comModal=false
                })
                .catch((error) => {
                    this.hideLoading()
                    this.$message.error("请求出错")
                    this.comModal=false
                });
        }
    },
    addCoupon(item){
        this.couInfo={
            COUPONTEMPLATESNOWID:item.SNOWID,
            COUPONPREFIX:"No.",
            GENERATECOUNT:"",
            STARTNUM:"",
        }
        this.couModal=true
    },
    submitCouForm() {
        if (this.canDo) {
            this.$refs.cForm.validate((valid) => {
                if (valid) {
                    this.sendAddCoupon()
                } else {
                    return false;
                }
            });
        }
    },
    sendAddCoupon(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.couInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.couInfo)).toUpperCase())
        this.$http.post("CouponTemplate/GenerateCoupon", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("优惠券生成成功")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
                this.couModal=false
            })
            .catch((error) => {
                this.hideLoading()
                this.$message.error("请求出错")
                this.couModal=false
            });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>