<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">预约单</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="预约人姓名" class="iw" ></el-input>
                <el-input v-model="sjh" placeholder="预约人手机号" class="iw" ></el-input>
                <el-select v-model="type" placeholder="预约单状态"   class="iw">
                        <el-option label="全部状态" value="" ></el-option>
                        <el-option label="预约中" value="0" ></el-option>
                        <el-option label="已完成" value="1" ></el-option>
                        <el-option label="取消预约" value="2" ></el-option>
                </el-select>
                <el-select v-model="station" placeholder="选择站点"  clearable class="iw">
                        <el-option v-for="item in stationList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <!-- <el-date-picker class="iwt" v-model="time" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker> -->
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="detailF">
                                <div class="ddh">订单号:{{props.row.ORDERINFO.ORDERNO}}　支付金额：{{props.row.je}}　优惠券：{{props.row.ORDERINFO.COUPONNUMBER}} 备注：{{props.row.ORDERINFO.REMARK}}</div>
                                <div class="ddh">
                                  <span v-if="props.row.ORDERINFO.PICKUPMETHOD == '1'">提货方式：预约保养</span>
                                  <span v-else-if="props.row.ORDERINFO.PICKUPMETHOD == '2'">提货方式：自提</span>　
                                  <span v-if="props.row.ORDERINFO.STATIONINFO">　自提点：{{props.row.ORDERINFO.STATIONINFO.NAME}}</span>
                                   <span v-if="props.row.ORDERINFO.ORDERSTATUS">　订单状态：
                                       <span v-if="props.row.ORDERINFO.ORDERSTATUS=='5'">已完成</span>
                                       <span v-else-if="props.row.ORDERINFO.ORDERSTATUS=='4'">已预约</span>
                                       <span v-else-if="props.row.ORDERINFO.ORDERSTATUS=='3'">支付失败</span>
                                       <span v-else-if="props.row.ORDERINFO.ORDERSTATUS=='2'">取消支付</span>
                                       <span v-else-if="props.row.ORDERINFO.ORDERSTATUS=='1'">已支付</span>
                                       <span v-else-if="props.row.ORDERINFO.ORDERSTATUS=='0'">未支付</span>
                                   </span>
                                </div>
                                <div class="sp">
                                    <div class="spItem" v-for="item in props.row.sp" :key="item.id">
                                        <div class="imgBox">
                                            <img  style="width: 100px;" :src="item.img" >
                                        </div>
                                        <div class="infoBox">
                                            <p class="name">{{item.name}} <small>{{item.gg}}</small> </p>
                                            <p class="num"> <small>¥</small> {{item.price}}　　×{{item.count}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TRUENAME" label="预约人姓名"></el-table-column>
                    <el-table-column prop="MOBIL" label="手机号"></el-table-column>
                    <el-table-column prop="CARNUMBER" label="车辆品牌"></el-table-column>
                    <el-table-column prop="CARMODEL" label="车辆型号"></el-table-column>
                    <el-table-column prop="CARYEARS" label="车辆年份"></el-table-column>
                    <el-table-column prop="STATIONINFO" label="预约站点">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATIONINFO">{{scope.row.STATIONINFO.NAME}}</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="RESERVETIME" label="到站时间"></el-table-column>
                    <el-table-column prop="STATUS" label="预约单状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='0'" style="color:#409EFF">预约中</span>
                            <span v-else-if="scope.row.STATUS=='1'" style="color:#67C23A">已完成</span>
                            <span v-else-if="scope.row.STATUS=='2'" style="color:#F56C6C">已取消</span>
                        </template>
                    </el-table-column>
                  
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "CouponList",
  data() {
    
   
   

    
    return {
        canDo:true,
        
        xm:"",
        sjh:"",
        type:"",
        station:"",
        stationList:[],
        time:"",


        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

    };
  },
  mounted() {
    this.loadItem=null
    this.getStationList()
    this.getPostList()
  },
  methods: {
    getStationList(){
      var params={
            PAGEINDEX:1,
            PAGESIZE:100,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Station/GetStationList", md5_data)
            .then((response) => {
              this.hideLoading()
              if (response.data.SUCCESS) {
                  this.stationList=response.data.DATA
              }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        // var st=""
        // var et=""
        // if(this.time.length>0){
        //     st=this.comjs.getDateStrByDate(this.time[0],"1")
        //     et=this.comjs.getDateStrByDate(this.time[1],"1")
        // }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            STATIONSNWOID:this.station,
            BUYERNAME:this.xm,
            BUYERPHONE:this.sjh,
            ORDERRESERVESTATUS:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ShoppingCart/GetReserveList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                var nl=[]
                for(var i=0;i<d.length;i++){
                    var di=d[i]
                    di.je = this.comjs.toFixedPrice(parseFloat(di.ORDERINFO.PAYERTOTALMONEY) / 100) 
                    
                    di.sp=[]
                    var cl=di.ORDERINFO.ORDERCOMMODITYLIST
                    for(var j=0;j<cl.length;j++){
                        var imgpath=""
                        for(var k=0;k<cl[j].COMMODITYINFO.FILELIST.length;k++){
                            if(cl[j].COMMODITYINFO.FILELIST[k].FILETYPE=="0"){
                                imgpath=cl[j].COMMODITYINFO.FILELIST[k].FILEPATH
                                break;
                            }
                        }
                        di.sp.push({
                            id:cl[j].COMMODITYINFO.SNOWID,
                            name:cl[j].COMMODITYINFO.NAME,
                            gg:cl[j].COMMODITYINFO.SPEC,
                            price:cl[j].UNITPRICE,
                            count:cl[j].BUYCOUNT,
                            img:imgpath,
                        })
                    }
                    nl.push(di)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },

    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
    .detailF{overflow: hidden;padding: 0 50px;}
    .detailF .ddh{color: #333;font-size: 14px;}
    .detailF .sp{overflow: hidden;margin-top: 5px;}
    .detailF .sp .spItem{background-color: #F8F8F9;border-radius: 3px;float:left;margin-right: 10px;}
    .detailF .sp .spItem .imgBox{float:left;width: 60px;height: 60px;overflow: hidden;}
    .detailF .sp .spItem .infoBox{height: 60px;float:left;color: #333;font-size: 15px;margin-left: 8px;margin-right: 8px;}
    .detailF .sp .spItem .infoBox .name{font-weight: bold;margin-top: 8px;}
    .detailF .sp .spItem .infoBox .num small{font-size: 14px;}
</style>