<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">站点人员列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="sjh" placeholder="请输入手机号" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TRUENAME" label="姓名"></el-table-column>
                    <el-table-column prop="MOBILE" label="手机号"></el-table-column>
                    <el-table-column prop="STATIONINFO" label="所在站点">
                         <template slot-scope="scope">
                            <span>{{scope.row.STATIONINFO.NAME}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "StationUserList",
  data() {

    return {
        canDo:true,
        
        xm:"",
        sjh:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MOBILE:this.sjh,
            USERTYPE:"3",
            TRUENAME:this.xm,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("User/GetOperateUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    detail(item){
        console.log(item)
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>