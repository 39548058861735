import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import qs from 'qs';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5';
import comjs from '../public/statics/js/common.js'

Vue.config.productionTip = false

// console.log("-------")
// console.log(router)
// const um={
//   path: '/UserManage',
//   name: 'UserManage',
//   component: () => import('./views/UserManage.vue')
// }
// router.addRoute('Home', um)

//addRoutes
//console.log(router.getRoutes())

//上传 ajax
const upload = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  headers: {
    'Content-Type': 'multipart/form-data' ,
  },
})

Vue.prototype.$upload = upload;


//普通ajax
const $axios = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 5000000,
});

Vue.prototype.$http = $axios;


//组件
Vue.prototype.$qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.comjs = comjs;
Vue.use(ElementUI);
Vue.use(VueCookies)
VueCookies.config('30d')

//注销
var pcLogout= (replace)=>{
  store.commit('setToken', "");  
  store.commit('setAdmin', "");  
  store.commit('setEndTime', "");  
  store.commit('setGuid', "");  
  store.commit('setName', "");  
  store.commit('setLoginName', "");  
  store.commit('setPassword', "");  
  store.commit('setOpenPage', "");

  // store.commit('setInitRouter', "");
  VueCookies.remove("setToken");
  VueCookies.remove("setAdmin");
  VueCookies.remove("setEndTime");
  VueCookies.remove("setGuid");
  VueCookies.remove("setName");
  VueCookies.remove("setLoginName");
  VueCookies.remove("setPassword");
  if(replace){
    router.replace("/")
  }
}
Vue.prototype.logout = pcLogout

//接口异常处理
var errorRequest=(code,msg,show)=>{
    if(code){
      code=code.toString()
      var  c=code.substring(0, 1);
      if(c=="1"){
        ElementUI.Message.error(msg+",即将回到登录界面")
        setTimeout(function(){
          pcLogout(true)
        },2000)
      }else{
        if(show){
          ElementUI.Message.error(msg)
        }
      }
    }else{
      ElementUI.Message.error(msg)
    }
    hideLoading()
}
Vue.prototype.errorRequest = errorRequest

//等待框
var loadItem=null
var showLoading=function(txt){
  loadItem = this.$loading({
    lock: true,
    text: txt,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
}
var hideLoading=function(){
  loadItem.close()
}
Vue.prototype.loadItem = loadItem
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading

// var addPageManage=function(){
//   const pmRouter={
//     path: '/PageManage',
//     name: 'PageManage',
//     component:  () => import('./views/PageManage.vue')
//   }
//   router.addRoute('Home', pmRouter)
// }
// Vue.prototype.addPageManage =addPageManage

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
