import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import AdminManage from '../views/AdminManage.vue'
import LabelManage from '../views/LabelManage.vue'
import CommodityManage from '../views/CommodityManage.vue'
import CouponTManage from '../views/CouponTManage.vue'
import CouponList from '../views/CouponList.vue'
import StationManage from '../views/StationManage.vue'
import CouponUserList from '../views/CouponUserList.vue'
import StationUserList from '../views/StationUserList.vue'
import OrderList from '../views/OrderList.vue'
import ReserveList from '../views/ReserveList.vue'
import StorehouseManage from '../views/StorehouseManage.vue'


import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)

// {
//   path: '/about',
//   name: 'About',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
// }


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/AdminManage',
        name: 'AdminManage',
        component: AdminManage
      },
      {
        path: '/LabelManage',
        name: 'LabelManage',
        component: LabelManage
      },
      {
        path: '/CommodityManage',
        name: 'CommodityManage',
        component: CommodityManage
      },
      {
        path: '/CouponTManage',
        name: 'CouponTManage',
        component: CouponTManage
      },
      {
        path: '/CouponList',
        name: 'CouponList',
        component: CouponList
      },
      {
        path: '/StationManage',
        name: 'StationManage',
        component: StationManage
      },
      {
        path: '/CouponUserList',
        name: 'CouponUserList',
        component: CouponUserList
      },
      {
        path: '/StationUserList',
        name: 'StationUserList',
        component: StationUserList
      },
      {
        path: '/OrderList',
        name: 'OrderList',
        component: OrderList
      },
      {
        path: '/ReserveList',
        name: 'ReserveList',
        component: ReserveList
      },
      {
        path: '/StorehouseManage',
        name: 'StorehouseManage',
        component: StorehouseManage
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
