<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">优惠券列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="type" placeholder="优惠券类型"   class="iw">
                        <el-option label="全部类型" value="" ></el-option>
                        <el-option label="抵扣券" value="1" ></el-option>
                        <el-option label="折扣券" value="2" ></el-option>
                </el-select>
                
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addCoupon" class="fr">生成优惠券</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="COUPONPREFIX" label="编号前缀"></el-table-column>
                    <el-table-column prop="COUPONMINNUM" label="号段最小值"></el-table-column>
                    <el-table-column prop="COUPONMAXNUM" label="号段最大值"></el-table-column>
                    <el-table-column prop="COUPONMAXNUM" label="总数量">
                        <template slot-scope="scope">
                            <span>{{scope.row.COUPONMAXNUM - scope.row.COUPONMINNUM +1 }}</span>
                           
                        </template>
                    </el-table-column>
                    <el-table-column prop="COUPOTYPE" label="优惠券类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.COUPOTYPE=='1'">抵扣券</span>
                            <span v-else-if="scope.row.COUPOTYPE=='2'">折扣券</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="COUPOVALUE" label="对应优惠"></el-table-column>
                    <el-table-column prop="COUPOCONDITION" label="满多少可用"></el-table-column>
                    <el-table-column prop="COUPOREMARK" label="描述"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="sendCoupon(scope.row)" type="text" size="small" style="color:#67C23A">发券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="生成优惠券" :visible.sync="couModal" width="500px">
            <el-form :model="couInfo" status-icon :rules="courules"  ref="cForm" label-width="100px" class="demo-uForm">
                <el-form-item label="选择模板" prop="COUPONTEMPLATESNOWID">
                    <el-select v-model="couInfo.COUPONTEMPLATESNOWID" placeholder="请选择模板" filterable  style="width:100%;">
                            <el-option v-for="item in ctList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="编号前缀" prop="COUPONPREFIX">
                     <el-input v-model="couInfo.COUPONPREFIX" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="起始号码" prop="STARTNUM">
                    <el-input v-model="couInfo.STARTNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="生成数量" prop="GENERATECOUNT">
                    <el-input v-model="couInfo.GENERATECOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitCouForm()">提交</el-button>
                <el-button @click="couModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="发券" :visible.sync="scModal" width="500px">
            <el-form :model="scInfo" status-icon :rules="scrules"  ref="sForm" label-width="100px" class="demo-uForm">
                <el-form-item label="选择发券人" prop="USERSNOWID">
                    <el-select v-model="scInfo.USERSNOWID" placeholder="选择发券人" filterable  style="width:100%;">
                            <el-option v-for="item in scList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="起始号码" prop="STARTNUM">
                    <el-input v-model="scInfo.STARTNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="结束号码" prop="ENDNUM">
                    <el-input v-model="scInfo.ENDNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitSCForm()">提交</el-button>
                <el-button @click="scModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CouponList",
  data() {
    
    var COUPONTEMPLATESNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择模板"));
      } else {
        callback();
      }
    };
    var COUPONPREFIX= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编号前缀"));
      } else {
        callback();
      }
    };
    var GENERATECOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入生成数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('生成数量只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('生成数量必须大于0'));
        }else {
          callback();
        }
    };
    var STARTNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入起始号码'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('起始号码只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('起始号码必须大于0'));
        }else {
          callback();
        }
    };
    var ENDNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入结束号码'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('结束号码只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('结束号码必须大于0'));
        }else if(parseInt(value) < parseInt(this.scInfo.STARTNUM)){
            callback(new Error('结束号码必须大于等于开始号码'));
        }else {
          callback();
        }
    };

    var USERSNOWID= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择一个发券人"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
       type:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        ctList:[],
        couModal:false,
        couInfo:{},
        courules: {
            COUPONTEMPLATESNOWID:[{ validator: COUPONTEMPLATESNOWID, trigger: "blur" }],
            COUPONPREFIX: [{ validator: COUPONPREFIX, trigger: "blur" }],
            GENERATECOUNT: [{ validator: GENERATECOUNT, trigger: "blur" }],
            STARTNUM: [{ validator: STARTNUM, trigger: "blur" }],
        },

        scList:[],
        scModal:false,
        scInfo:{},
        scrules: {
            USERSNOWID:[{ validator: USERSNOWID, trigger: "blur" }],
            STARTNUM: [{ validator: STARTNUM, trigger: "blur" }],
            ENDNUM: [{ validator: ENDNUM, trigger: "blur" }],
        },

    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getCTList()
    this.getSCList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            TYPE:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CouponTemplate/GetGenerateCoupon", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    getCTList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("CouponTemplate/GetCouponTemplateListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                var nl=[]
                for(var i=0;i<d.length;i++){
                    var type=""
                    if(d[i].TYPE=="1"){
                        type="（抵扣券）"
                    }else if(d[i].TYPE=="2"){
                        type="（折扣券）"
                    }
                    var name=d[i].REMARK+type
                    nl.push({id:d[i].SNOWID,name:name})
                }
                this.ctList=nl
            } 
        })
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addCoupon(){
        this.couInfo={
            COUPONTEMPLATESNOWID:"",
            COUPONPREFIX:"No.",
            GENERATECOUNT:"",
            STARTNUM:"",
        }
        this.couModal=true
    },
    submitCouForm() {
        if (this.canDo) {
            this.$refs.cForm.validate((valid) => {
                if (valid) {
                    this.sendAddCoupon()
                } else {
                    return false;
                }
            });
        }
    },
    sendAddCoupon(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.couInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.couInfo)).toUpperCase())
        this.$http.post("CouponTemplate/GenerateCoupon", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("优惠券生成成功")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
                this.couModal=false
                this.canDo=true
            })
            .catch((error) => {
                this.hideLoading()
                this.$message.error("请求出错")
                this.couModal=false
                this.canDo=true
            });
    },



    //发券

    getSCList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("/User/GetOperateUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                var nl=[]
                for(var i=0;i<d.length;i++){
                    var name=d[i].TRUENAME+"("+d[i].MOBILE+")"
                    nl.push({id:d[i].SNOWID,name:name})
                }
                this.scList=nl
            } 
        })
    },
    sendCoupon(item){
         this.scInfo={
            USERSNOWID:"",
            COUPONSNOWID:item.SNOWID,
            ENDNUM:"",
            STARTNUM:"",
        }
        this.scModal=true
    },
    submitSCForm() {
        if (this.canDo) {
            this.$refs.sForm.validate((valid) => {
                if (valid) {
                    this.sendSendCoupon()
                } else {
                    return false;
                }
            });
        }
    },
    sendSendCoupon(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.scInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.scInfo)).toUpperCase())
        this.$http.post("CouponTemplate/CouponToOperators", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(response.data.DATA.length ==0){
                        this.$message.success("优惠券发放成功")
                    }else{
                        var str=""
                        var d=response.data.DATA
                        for(var i=0;i<d.length;i++){
                            if(i==0){
                                str=d[i].COUPONNUMBER
                            }else{
                                str = str+"、"+d[i].COUPONNUMBER
                            }
                        }
                        this.$alert(str, '下列为已发放号段，无法重复发放', {
                            confirmButtonText: '我知道了',
                            callback: action => {
                                //
                            }
                        });
                    } 
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
                this.scModal=false
                this.canDo=true
            })
            .catch((error) => {
                this.hideLoading()
                this.$message.error("请求出错")
                this.scModal=false
                this.canDo=true
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>