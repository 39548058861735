<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">站点管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="mc" placeholder="请输入名称" class="iw" ></el-input>
                <el-input v-model="dz" placeholder="请输入地址" class="iw" ></el-input>
                <el-input v-model="lxr" placeholder="请输入联系人" class="iw" ></el-input>
                
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ADDRESS" label="地址"></el-table-column>
                    <el-table-column prop="CONTACT" label="联系人"></el-table-column>
                    <el-table-column prop="MOBILE" label="联系电话"></el-table-column>
                    <el-table-column prop="BUSINESSHOURS" label="营业时间"></el-table-column>
                    <el-table-column prop="REMARK" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <div id="map" class="map"></div>
                <div class="overflow:hidden">
                    <el-form-item label="经度" prop="LON">
                        <el-input v-model="postInfo.LON" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="纬度" prop="LAT">
                        <el-input v-model="postInfo.LAT" autocomplete="off"></el-input>
                    </el-form-item>
                </div>
                
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="ADDRESS">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="CONTACT">
                    <el-input v-model="postInfo.CONTACT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="MOBILE">
                    <el-input v-model="postInfo.MOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="营业时间" prop="BUSINESSHOURS">
                    <el-input v-model="postInfo.BUSINESSHOURS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "StationManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var ADDRESS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地址"));
      } else {
        callback();
      }
    };
    var CONTACT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人"));
      } else {
        callback();
      }
    };
    var MOBILE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系电话"));
      } else {
        callback();
      }
    };
    var BUSINESSHOURS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入营业时间"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入备注"));
      } else {
        callback();
      }
    };

    var LON =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入经度'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('经度只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('经度必须大于0'));
        }else {
          callback();
        }
    };

    var LAT =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入纬度'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('纬度只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('纬度必须大于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        mc:"",
        dz:"",
        lxr:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        map:null,
        marker:null,
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
            CONTACT: [{ validator: CONTACT, trigger: "blur" }],
            MOBILE: [{ validator: MOBILE, trigger: "blur" }],
            BUSINESSHOURS: [{ validator: BUSINESSHOURS, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            LON: [{ validator: LON, trigger: "blur" }],
            LAT: [{ validator: LAT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    initMap(center){
        this.map = new window.qq.maps.Map(document.getElementById("map"), {
            center: center,
            zoom: 14,
            mapTypeControlOptions: {
                mapTypeIds: [
                    window.qq.maps.MapTypeId.ROADMAP,
                    window.qq.maps.MapTypeId.SATELLITE,
                    window.qq.maps.MapTypeId.HYBRID
                ],
                //设置控件位置相对上方中间位置对齐
                position: window.qq.maps.ControlPosition.TOP_LEFT
            },
            panControlOptions: { position: window.qq.maps.ControlPosition.TOP_RIGHT },
            zoomControlOptions: { position: window.qq.maps.ControlPosition.RIGHT_TOP, }
        });
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            TYPE:"1",
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.mc,
            ADDRESS:this.dz,
            CONTACT:this.lxr,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Station/GetStationList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TYPE:"1",
            NAME:"",
            ADDRESS:"",
            CONTACT:"",
            MOBILE:"",
            BUSINESSHOURS:"",
            REMARK:"",
            LON:118.711152,
            LAT:32.151818
        }
        this.doTypeStr="添加站点"
        this.doType="1"
        this.postModal=true
        setTimeout( () =>{
            if(this.map ==null){
                var center = new window.qq.maps.LatLng(32.151818, 118.711152);
                this.initMap(center)
                if(this.marker == null){
                    this.marker= new window.qq.maps.Marker({
                        position: center,
                        map: this.map,
                        draggable:true,
                    })
                    var _this=this
                    window.qq.maps.event.addListener(this.marker, 'dragend', function (e) {
                        _this.postInfo.LON= e.latLng.lng
                        _this.postInfo.LAT= e.latLng.lat
                    })
                    
                }
            }
        },50)
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Station/CreateStation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            TYPE:"1",
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            ADDRESS:item.ADDRESS,
            CONTACT:item.CONTACT,
            MOBILE:item.MOBILE,
            BUSINESSHOURS:item.BUSINESSHOURS,
            REMARK:item.REMARK,
            LON:item.LON,
            LAT:item.LAT,
        }
        this.doTypeStr="添加"
        this.doType="2"
        this.postModal=true

        setTimeout( () =>{
            var center = new window.qq.maps.LatLng(item.LAT, item.LON);
            if(this.map ==null){
                this.initMap(center)
                if(this.marker == null){
                    this.marker= new window.qq.maps.Marker({
                        position: center,
                        map: this.map,
                        draggable:true,
                    })
                    var _this=this
                    window.qq.maps.event.addListener(this.marker, 'dragend', function (e) {
                        _this.postInfo.LON= e.latLng.lng
                        _this.postInfo.LAT= e.latLng.lat
                    })
                    
                }
            }
            if(this.map && this.marker){
                this.marker.setPosition(center)
            }
        },50)
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Station/EditStation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除站点：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Station/DeleteStation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    updataMarkerAndCenter(){
        if(this.map && this.marker){
             this.$refs.uForm.validate((valid) => {
                 if (valid) {
                     var center = new window.qq.maps.LatLng(this.postInfo.LAT, this.postInfo.LON);
                     this.marker.setPosition(center)
                 }
             });
        }
    },
  },
  computed: {
  },
  watch: {
    'postInfo.LON': {
         handler: function() {
            this.updataMarkerAndCenter()
         },
    },
    'postInfo.LAT': {
         handler: function() {
            this.updataMarkerAndCenter()
         },
    }
  },
};
</script>
<style >
.map{width: 100%;height: 300px;margin-bottom: 20px;}
</style>