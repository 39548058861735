<template>
  <div id="app" class="appMain">
    <div class="appHead">
      <div class="logoBox">
          <!-- <img src="statics/img/logo.png" alt=""> -->
      </div>
      <div class="loginInfo">
        <img :src="headImg" class="headImg">
        <div class="ddBox">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item @click.native="changeUserModal = true">修改资料</el-dropdown-item> -->
                <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
    </div>
    <div class="appLeft">
        <el-menu
            :default-active="defActive"
            background-color="#545c64"
            text-color="#fff"
            :collapse="isCollapse"
            router
            active-text-color="#ffd04b">

            <el-submenu index="Home1">
              <template slot="title">
                <i class="el-icon-user-solid"></i>
                <span>人员管理</span>
              </template>
              <el-menu-item index="/AdminManage">系统管理员</el-menu-item>
              <el-menu-item index="/StationUserList">站点人员列表</el-menu-item>
              <el-menu-item index="/CouponUserList">发券人员列表</el-menu-item>
              <!-- <el-menu-item index="/LabelManage">公众号标签</el-menu-item> -->
            </el-submenu>
            <!-- disabled -->
            <el-submenu index="Home2">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>基础数据</span>
              </template>
                <el-menu-item index="/CouponTManage">优惠券模板</el-menu-item>
                <el-menu-item index="/CommodityManage">商品管理</el-menu-item>
                <el-menu-item index="/StationManage">站点管理</el-menu-item>
                <el-menu-item index="/StorehouseManage">提货点管理</el-menu-item>
            </el-submenu>
            <el-submenu index="Home3">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>运营数据</span>
              </template>
                <el-menu-item index="/CouponList">优惠券列表</el-menu-item>
                <el-menu-item index="/OrderList">订单列表</el-menu-item>
                <el-menu-item index="/ReserveList">预约单列表</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
    <div class="appRight">
        <router-view></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="resetPwdForm('cpForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      isAdmin:false,

      isCollapse:false,

      loadpage:false,
      defActive:"",
      userName:"",
      headImg:"statics/img/headImg.png",


      logoutModal:false,
      changePwdModal:false,
      changePwd: {
          oldPass:'',
          newPass: '',
          checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
    };
  },
  mounted() {
    //console.log("执行HOME")
    if(this.$store.state.admin=="1"){
        this.isAdmin=true
    }
    this.userName=this.$store.state.name
    this.loadItem=null
    this.defActive='/'+this.$route.name
    this.loadpage=true
  },
  methods: {
    //退出登录
    logoutSend(){
      this.logoutModal=false
      this.logout(true)
    },
    //修改密码
    submitPwdForm(formName) {
        this.$refs.cpForm.validate((valid) => {
          if (valid) {
            //console.log(this.changePwd)
            var params={
              SNOWID:this.$store.state.guid,
              OLDPASSWORD:this.changePwd.oldPass,
              NEWPASSWORD:this.changePwd.newPass
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("UserManage/UpdatePassword", md5_data)
              .then((response) => {
                //console.log(response)
                if (response.data.SUCCESS) {
                    // this.$cookies.set("user_pwd",this.changePwd.newPass)
                    this.$message.success("修改成功");
                    this.changePwdModal=false
                    this.$refs.cpForm.resetFields();
                } else {
                  this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
              })
              .catch((error) => {
                this.canLogin=true
                this.fullscreenLoading = false
                this.$message.error("登录失败，请稍后再试");
            });
            } else {
              return false;
            }
        });
      },
    resetPwdForm(formName) {
      this.$refs.cpForm.resetFields();
    },
  },
  computed: {
   //
  },
  watch: {
    //
  },
};
</script>

<style>
.appMain{width: 100%;height: 100%;position: relative;overflow: hidden;}

.appHead{height: 56px;position: absolute;left: 0px;top: 0px;right: 0px;background-color: #FFFFFF;}
.appLeft{width: 200px;position: absolute;left: 0px;top: 56px;bottom: 0px;background-color: #545c64;overflow-x: hidden;overflow-y: auto;}
.appRight{position: absolute;left: 200px;bottom: 0px;right: 0px;top: 56px;overflow: auto;background-color: #F8F8F8;border-radius: 4px;}





.logoBox{width: 200px;height: 55px;display: block; background-color: #545c64;overflow: hidden;border-bottom: 1px solid #FFFFFF;}
.logoBox img{width: 150px;float:left;margin-left: 12px;margin-top: 4px;}

.loginInfo{overflow: hidden;height: 56px;position: absolute;right: 20px;top: 0;}
.loginInfo .headImg{width: 36px;float: left;margin-top: 10px;}
.appHead .headCon{height: 56px;float:left;overflow: hidden;}
.appHead .headCon .btnItem{float:left;margin-right: 20px;}
.loginInfo .ddBox{float:left;margin-left: 16px;cursor: pointer;margin-top: 15px;}
.appMain .maxpage{left: 0;right: 0;top: 0;bottom: 0;overflow: hidden;padding: 0;}
.el-menu{border-right: 0!important;}
/* .el-menu-item:hover{background-color: #FFFFFF!important;} */
</style>
