<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">订单列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="sjh" placeholder="请输入手机号" class="iw" ></el-input>
                <el-select v-model="type" placeholder="订单状态"   class="iw">
                        <el-option label="全部状态" value="" ></el-option>
                        <el-option label="已支付" value="1" ></el-option>
                        <el-option label="未支付" value="0" ></el-option>
                        <el-option label="取消支付" value="2" ></el-option>
                        <el-option label="支付失败" value="3" ></el-option>
                        <el-option label="已预约" value="4" ></el-option>
                        <el-option label="已完成" value="5" ></el-option>
                </el-select>
                
                <el-date-picker class="iwt" v-model="time" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="detailF">
                                <div class="ddh">订单号:{{props.row.ORDERNO}}</div>
                                <div class="sp">
                                    <div class="spItem" v-for="item in props.row.sp" :key="item.id">
                                        <div class="imgBox">
                                            <img  style="width: 100px;" :src="item.img" >
                                        </div>
                                        <div class="infoBox">
                                            <p class="name">{{item.name}} <small>{{item.gg}}</small> </p>
                                            <p class="num"> <small>¥</small> {{item.price}}　　×{{item.count}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="yyxx" v-if="props.row.RESERVEINFO">
                                     汽车品牌：{{props.row.RESERVEINFO.CARNUMBER}}　
                                     汽车型号：{{props.row.RESERVEINFO.CARMODEL}}　
                                     汽车年份：{{props.row.RESERVEINFO.CARYEARS}}　
                                     预约人：{{props.row.RESERVEINFO.TRUENAME}}　
                                     预约人电话：{{props.row.RESERVEINFO.MOBIL}}　
                                     预约站点：{{props.row.RESERVEINFO.STATIONINFO.NAME}}　
                                     到站时间：{{props.row.RESERVEINFO.RESERVETIME}}　
                                     <span v-if="props.row.RESERVEINFO.STATUS=='0'">预约状态：预约中</span>
                                     <span v-else-if="props.row.RESERVEINFO.STATUS=='1'">预约状态：已完成</span>
                                     <span v-else-if="props.row.RESERVEINFO.STATUS=='2'">预约状态：已取消</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BUYERNAME" label="姓名"></el-table-column>
                    <el-table-column prop="BUYERPHONE" label="手机号"></el-table-column>
                    <el-table-column prop="je" label="支付金额"></el-table-column>
                    <el-table-column prop="PICKUPMETHOD" label="提货方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PICKUPMETHOD=='1'">预约保养</span>
                            <span v-else-if="scope.row.PICKUPMETHOD=='2'" >自提</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="STATIONINFO" label="已选自提点">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATIONINFO">{{scope.row.STATIONINFO.NAME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ORDERSTATUS" label="订单状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ORDERSTATUS=='0'" style="color:#909399">未支付</span>
                            <span v-else-if="scope.row.ORDERSTATUS=='1'" style="color:#409EFF">已支付</span>
                            <span v-else-if="scope.row.ORDERSTATUS=='2'" style="color:#F56C6C">取消支付</span>
                            <span v-else-if="scope.row.ORDERSTATUS=='3'" style="color:#F56C6C">支付失败</span>
                            <span v-else-if="scope.row.ORDERSTATUS=='4'" style="color:#409EFF">已预约</span>
                            <span v-else-if="scope.row.ORDERSTATUS=='5'" style="color:#67C23A">已完成</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="COUPONNUMBER" label="优惠券编号">
                        <template slot-scope="scope">
                            <span v-if="scope.row.COUPONNUMBER==''">/</span>
                            <span v-else>{{scope.row.COUPONNUMBER}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="REMARK" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                          <el-button @click="finish(scope.row)" type="text" size="small" style="color:#67C23A" v-if="scope.row.ORDERSTATUS=='1' || scope.row.ORDERSTATUS=='4'">确认收货</el-button>
                          <span v-else>/</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "CouponList",
  data() {
    
    return {
        canDo:true,
        
        xm:"",
        sjh:"",
        type:"",
        time:"",
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.time.length>0){
            st=this.comjs.getDateStrByDate(this.time[0],"1")
            et=this.comjs.getDateStrByDate(this.time[1],"1")
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            BUYERNAME:this.xm,
            BUYERPHONE:this.sjh,
            ORDERSTATUS:this.type,
            STARTDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ShoppingCart/GetOrderList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                var nl=[]
                for(var i=0;i<d.length;i++){
                    var di=d[i]
                    di.je = this.comjs.toFixedPrice(parseFloat(di.PAYERTOTALMONEY) / 100) 
                    
                    di.sp=[]
                    for(var j=0;j<di.ORDERCOMMODITYLIST.length;j++){
                        var imgpath=""
                        for(var k=0;k<di.ORDERCOMMODITYLIST[j].COMMODITYINFO.FILELIST.length;k++){
                            if(di.ORDERCOMMODITYLIST[j].COMMODITYINFO.FILELIST[k].FILETYPE=="0"){
                                imgpath=di.ORDERCOMMODITYLIST[j].COMMODITYINFO.FILELIST[k].FILEPATH
                                break;
                            }
                        }
                        di.sp.push({
                            id:di.ORDERCOMMODITYLIST[j].COMMODITYINFO.SNOWID,
                            name:di.ORDERCOMMODITYLIST[j].COMMODITYINFO.NAME,
                            gg:di.ORDERCOMMODITYLIST[j].COMMODITYINFO.SPEC,
                            price:di.ORDERCOMMODITYLIST[j].UNITPRICE,
                            count:di.ORDERCOMMODITYLIST[j].BUYCOUNT,
                            img:imgpath,
                        })
                    }
                    nl.push(di)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },

    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    finish(item){
      //
      if(this.canDo){
            this.$confirm('确定此订单客户已收货？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendFinish(item.SNOWID)
            })
        }
    },
    sendFinish(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id,ORDERSTATUS:"5"}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id,ORDERSTATUS:"5"})).toUpperCase())
        this.$http.post("ShoppingCart/EditOrderStatus", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已完成")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
    .detailF{overflow: hidden;padding: 0 50px;}
    .detailF .ddh{color: #333;font-size: 14px;}
    .detailF .sp{overflow: hidden;margin-top: 5px;}
    .detailF .sp .spItem{background-color: #F8F8F9;border-radius: 3px;float:left;margin-right: 10px;}
    .detailF .sp .spItem .imgBox{float:left;width: 60px;height: 60px;overflow: hidden;}
    .detailF .sp .spItem .infoBox{height: 60px;float:left;color: #333;font-size: 15px;margin-left: 8px;margin-right: 8px;}
    .detailF .sp .spItem .infoBox .name{font-weight: bold;margin-top: 8px;}
    .detailF .sp .spItem .infoBox .num small{font-size: 14px;}

    .yyxx{color: #409EFF;margin-top: 4px;}
</style>