<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">发券人员列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="xm" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="sjh" placeholder="请输入手机号" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TRUENAME" label="姓名"></el-table-column>
                    <el-table-column prop="MOBILE" label="手机号"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="detail(scope.row)" type="text" size="small" style="color:#E6A23C">优惠券明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="detailTitle" :visible.sync="dModal" width="1000px" top="50px">
                <div style="overflow:hidden;margin-bottom:20px;">
                    <el-select v-model="status" placeholder="使用状态"   class="iws">
                            <el-option label="全部状态" value="" ></el-option>
                            <el-option label="未使用" value="0" ></el-option>
                            <el-option label="已使用" value="1" ></el-option>
                    </el-select>
                    <el-button type="primary" @click="getDetailList">查询</el-button>
                </div>  
                <el-table :data="dList" stripe style="width: 100%" :height="dialogMaxHeigh"> 
                    <el-table-column prop="COUPONINFO" label="优惠券说明">
                        <template slot-scope="scope">
                            <span>{{scope.row.COUPONINFO.COUPOREMARK}}</span>
                        </template>
                    </el-table-column>
                     <el-table-column prop="COUPONINFO" label="优惠券类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.COUPONINFO.COUPOTYPE=='1'">抵扣券</span>
                            <span v-else-if="scope.row.COUPONINFO.COUPOTYPE=='2'">折扣券</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="COUPONINFO" label="优惠券价值">
                        <template slot-scope="scope">
                            <span>{{scope.row.COUPONINFO.COUPOVALUE}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="COUPONNUMBER" label="优惠券号"></el-table-column>
                    <el-table-column prop="STATUS" label="使用情况">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='1'" style="color:#67C23A">已使用</span>
                            <span v-else-if="scope.row.STATUS=='0'" style="color:#909399">未使用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.STATUS=='1'" @click="order(scope.row)" type="text" size="small" style="color:#E6A23C">查看订单</el-button>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                </el-table>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "CouponUserList",
  data() {

    return {
        canDo:true,
        
        xm:"",
        sjh:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        status:"",
        dialogMaxHeigh:0,
        detailTitle:"",
        dModal:false,
        uid:"",
        dList:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.dialogMaxHeigh = h -260
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MOBILE:this.sjh,
            USERTYPE:"2",
            TRUENAME:this.xm,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("User/GetOperateUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    detail(item){
        this.detailTitle = item.TRUENAME+"-优惠券明细"
        this.uid=item.SNOWID
        this.dList=[]
        this.getDetailList()
        this.dModal=true
    },
    getDetailList(){
        var params={
            USERSNOWID:this.uid,
            STATUS:this.status,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("User/GetOperateCouponList", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
                this.dList=response.data.DATA
            } else {
                this.dList=[]
            }
            })
            .catch((error) => {
                this.dList=[]
        });
    },
    order(item){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>