<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">商品管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入商品名" class="iw" ></el-input>

                
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="商品名"></el-table-column>
                    <el-table-column prop="SPEC" label="规格"></el-table-column>
                    <el-table-column prop="PRICE" label="金额"></el-table-column>
                    <el-table-column prop="INSTOCK" label="库存"></el-table-column>
                    <el-table-column prop="REMARK" label="商品介绍"></el-table-column>
                    <el-table-column prop="SHARETITLE" label="分享标题"></el-table-column>
                    <el-table-column prop="SHARECONTENT" label="分享描述"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="editImg(scope.row)" type="text" size="small" style="color:#409EFF">图片</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="商品名" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格" prop="SPEC">
                    <el-input v-model="postInfo.SPEC" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="金额" prop="PRICE">
                    <el-input v-model="postInfo.PRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="INSTOCK">
                    <el-input v-model="postInfo.INSTOCK" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="商品介绍" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分享标题" prop="SHARETITLE">
                    <el-input v-model="postInfo.SHARETITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分享描述" prop="SHARECONTENT">
                    <el-input v-model="postInfo.SHARECONTENT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="商品图片设置" :visible.sync="imgModal" width="800px" top="50px">
            <el-form :model="imgInfo" status-icon :rules="imgrules"  ref="iForm" label-width="100px" class="demo-uForm" >
                <div class="modalOut" :style="{width:'100%',height:dialogMaxHeigh+ 'px'}">
                    <el-form-item label="缩略图" prop="SL">
                            <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :http-request="uploadFun"
                                :before-upload="beforeAvatarUploadSL">
                                <div class="avatar"  v-if="SLFile.type!='3'">
                                    <img :src="SLFile.url">
                                    <i class="el-icon-delete" @click.stop="delSLImg"></i>
                                </div>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </el-form-item>
                    <el-form-item label="分享图" prop="FX">
                            <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :http-request="uploadFun"
                                :before-upload="beforeAvatarUploadFX">
                                <div class="avatar"  v-if="FXFile.type!='3'">
                                    <img :src="FXFile.url">
                                    <i class="el-icon-delete" @click.stop="delFXImg"></i>
                                </div>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </el-form-item>
                    <el-form-item label="轮播图" prop="LB">
                            <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :http-request="uploadFun"
                                :before-upload="beforeAvatarUploadLB"
                                multiple
                                >
                                <div v-if="imgInfo.LB!=0" style="overflow:hidden;">
                                    <div  v-for="(item,index) in LBFile" :key="index">
                                        <div class="avatar avatars" v-if="item.type!='3'">
                                            <img :src="item.url">
                                            <i class="el-icon-delete" @click.stop="delLBImg(index)"></i>
                                        </div>
                                    </div>
                                </div>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </el-form-item>
                    <el-form-item label="详情图" prop="XQ">
                            <el-upload
                                class="avatar-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :http-request="uploadFun"
                                :before-upload="beforeAvatarUploadXQ"
                                multiple
                                >
                                <div v-if="imgInfo.XQ!=0" style="overflow:hidden;">
                                    <div  v-for="(item,index) in XQFile" :key="index">
                                        <div class="avatar avatars" v-if="item.type!='3'">
                                            <img :src="item.url">
                                            <i class="el-icon-delete" @click.stop="delXQImg(index)"></i>
                                        </div>
                                    </div>
                                </div>
                                
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                    </el-form-item>
                </div>
                <el-form-item>
                <el-button type="primary" @click="submitImgForm()">提交</el-button>
                <el-button @click="imgModal = false">取消</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "CommodityManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入商品名"));
      } else {
        callback();
      }
    };
    var SPEC = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入规格"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入商品介绍"));
      } else {
        callback();
      }
    };
    var SHARETITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入分享标题"));
      } else {
        callback();
      }
    };

    var SHARECONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入分享描述"));
      } else {
        callback();
      }
    };
    var PRICE =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入商品金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('商品金额只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('商品金额必须大于0'));
        }else {
          callback();
        }
    };
    var INSTOCK =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入库存'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('库存只能是数字'));
        }else if(!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)){
            callback(new Error('库存必须大于0'));
        }else {
          callback();
        }
    };



    var SL = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("请选择商品缩略图"));
      } else {
        callback();
      }
    };
    var FX = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("请选择商品分享图"));
      } else {
        callback();
      }
    };
    var XQ = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("请选择商品详情图"));
      } else {
        callback();
      }
    };
    var LB = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("请选择商品轮播图"));
      } else {
        callback();
      }
    };
    return {
        dialogMaxHeigh:0,
        canDo:true,
        
        name:"",       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            SPEC: [{ validator: SPEC, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            SHARETITLE: [{ validator: SHARETITLE, trigger: "blur" }],
            SHARECONTENT: [{ validator: SHARECONTENT, trigger: "blur" }],
            PRICE: [{ validator: PRICE, trigger: "blur" }],
            INSTOCK: [{ validator: INSTOCK, trigger: "blur" }],
        },
        imgModal:false,
        imgInfo:{
            SL:0,
            FX:0,
            XQ:0,
            LB:0,
        },
        imgrules: {
            SL: [{ validator: SL, trigger: "blur" }],
            FX: [{ validator: FX, trigger: "blur" }],
            XQ: [{ validator: XQ, trigger: "blur" }],
            LB: [{ validator: LB, trigger: "blur" }],
        },

        SLFile:{type:"3",file:"",id:"",url:"",},
        FXFile:{type:"3",file:"",id:"",url:"",},
        XQFile:[],
        LBFile:[],
        nowCom:"",
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Commodity/GetCommodityList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            SPEC:"",
            PRICE:"",
            REMARK:"",
            SHARETITLE:"",
            SHARECONTENT:"",
            INSTOCK:"",
        }
        this.doTypeStr="添加商品"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Commodity/CreateCommodity", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            SPEC:item.SPEC,
            PRICE:item.PRICE,
            REMARK:item.REMARK,
            SHARETITLE:item.SHARETITLE,
            SHARECONTENT:item.SHARECONTENT,
            INSTOCK:item.INSTOCK,
        }
        this.doTypeStr="修改商品"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Commodity/EditCommodiy", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除商品：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Commodity/DeleteCommodity", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    editImg(item){
       if(this.canDo){
            this.nowCom=item.SNOWID
            this.SLFile={type:"3",file:null,id:"",url:"",}
            this.FXFile={type:"3",file:null,id:"",url:"",}
            this.XQFile=[]
            this.LBFile=[]
            this.imgInfo={
                SL:0,
                FX:0,
                XQ:0,
                LB:0,
            }
            this.getImg(item.SNOWID)
       }

    },
    getImg(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SOURCEID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SOURCEID:id})).toUpperCase())
        this.$http.post("File/GetFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    if(d.length>0){
                        for(var i=0;i<d.length;i++){
                            var dItem={type:"2",file:null,id:d[i].SNOWID,url:d[i].FILEPATH}
                            if(d[i].FILETYPE=="0"){
                                this.SLFile=dItem
                                this.imgInfo.SL=1
                            }else if(d[i].FILETYPE=="1"){
                                this.FXFile=dItem
                                this.imgInfo.FX=1
                            }else if(d[i].FILETYPE=="2"){
                                this.XQFile.push(dItem)
                                this.imgInfo.XQ=1
                            }else if(d[i].FILETYPE=="3"){
                                this.LBFile.push(dItem)
                                this.imgInfo.LB=1
                            }
                        }
                    }
                    this.imgModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    uploadFun(file){
        console.log(file)
    },
    beforeAvatarUploadSL(file){
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('缩略图只能是 JPG 格式!');
        }else if (!isLt2M) {
          this.$message.error('缩略图大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            var url = _URL.createObjectURL(file);
            this.imgInfo.SL=1
            this.SLFile.type="1"
            this.SLFile.file=file
            this.SLFile.url=url
        }
        return isJPG && isLt2M;
    },
    delSLImg(){
        this.imgInfo.SL=0
        this.SLFile.type="3"
        this.SLFile.url=""
    },
    beforeAvatarUploadFX(file){
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('分享图只能是 JPG 格式!');
        }else if (!isLt2M) {
          this.$message.error('分享图大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            var url = _URL.createObjectURL(file);
            this.imgInfo.FX=1
            this.FXFile.type="1"
            this.FXFile.file=file
            this.FXFile.url=url
        }
        return isJPG && isLt2M;
    },
    delFXImg(){
        this.imgInfo.FX=0
        this.FXFile.type="3"
        this.FXFile.url=""
    },
    beforeAvatarUploadLB(file){
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
       

        if (!isJPG) {
          this.$message.error('轮播图只能是 JPG 格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            var  url = _URL.createObjectURL(file);
            
            this.imgInfo.LB=1
            var imgItem={type:"1",file:file,id:"",url:url}
            this.LBFile.push(imgItem)
        }
        return isJPG;
    },
    delLBImg(index){
        //console.log(index)
        this.LBFile[index].type="3"
        this.LBFile[index].url=""

        var delCount=0
        for(var i=0;i<this.LBFile.length;i++){
            if(this.LBFile[i].type=="3"){
                delCount++
            }
        }
        if(delCount == this.LBFile.length){
            this.imgInfo.LB=0
        }else{
            this.imgInfo.LB=1
        }
    },
    beforeAvatarUploadXQ(file){
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
       

        if (!isJPG) {
          this.$message.error('详情图只能是 JPG 格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            var  url = _URL.createObjectURL(file);
            
            this.imgInfo.XQ=1
            var imgItem={type:"1",file:file,id:"",url:url}
            this.XQFile.push(imgItem)
        }
        return isJPG;
    },
    delXQImg(index){
        //console.log(index)
        this.XQFile[index].type="3"
        this.XQFile[index].url=""

        var delCount=0
        for(var i=0;i<this.XQFile.length;i++){
            if(this.XQFile[i].type=="3"){
                delCount++
            }
        }
        if(delCount == this.XQFile.length){
            this.imgInfo.XQ=0
        }else{
            this.imgInfo.XQ=1
        }
    },
    submitImgForm() {
        if (this.canDo) {
            this.$refs.iForm.validate((valid) => {
                if (valid) {
                    this.canDo=false
                    this.showLoading("请稍后")
                    if(this.SLFile.type=="1"){
                        this.addImg("0",this.SLFile.file)
                    }
                    if(this.SLFile.type!="2" && this.SLFile.id!=""){
                        this.delImg(this.SLFile.id)
                    } 

                    if(this.FXFile.type=="1"){
                        this.addImg("1",this.FXFile.file)
                    }
                    if(this.FXFile.type!="2" && this.FXFile.id!=""){
                        this.delImg(this.FXFile.id)
                    }

                    for(var i=0;i<this.XQFile.length;i++){
                        if(this.XQFile[i].type=="1"){
                             this.addImg("2",this.XQFile[i].file)
                        }
                        if(this.XQFile[i].type!="2" && this.XQFile[i].id!=""){
                            this.delImg(this.XQFile[i].id)
                        }
                    }
                    for(var j=0;j<this.LBFile.length;j++){
                        if(this.LBFile[j].type=="1"){
                             this.addImg("3",this.LBFile[j].file)
                        }
                        if(this.LBFile[j].type!="2" && this.LBFile[j].id!=""){
                            this.delImg(this.LBFile[j].id)
                        }
                    }

                    setTimeout(()=>{
                        this.canDo=true
                        this.imgModal=false
                        this.$message.success("已保存")
                        this.hideLoading()
                    },2000)
                    
                } else {
                    return false;
                }
            });
        }
    },
    addImg(type,file){
        var fd = new FormData();
        fd.append("file", file);
        this.$upload.post("File/UploadFile?SOURCEID="+this.nowCom+"&UPLOADTYPE=1&FILETYPE="+type, fd)
            .then((response) => {
                console.log(response)
            })
    },
    delImg(id){
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("File/DeleteFile", md5_data)
            .then((response) => {
                console.log(response.data)
            })
    }

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
.avatar{ max-width: 600px;line-height: 0;position: relative;}
.avatars{margin-top: 10px;background-color: #ECECEC;}
.avatar img{max-width: 100%;}
.el-icon-delete{font-size: 20px;position: absolute;bottom:5px;right: 5px;cursor: pointer;color: #F56C6C;}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.modalOut{overflow: auto;}
</style>